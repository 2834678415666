import React, {useEffect, useState} from 'react';

export const useCountDownTimer = (timesUpCallback) => {
  const [seconds, setSeconds] = useState(120);
  useEffect(() => {
    let timeoutId;

    if (seconds > 0) {
      timeoutId = setTimeout(() => setSeconds(seconds - 1), 1000);
    }
    if (seconds <= 0) {
      timesUpCallback();
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [seconds, timesUpCallback]);

  return [seconds, setSeconds];
};
