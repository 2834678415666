import React, {useMemo, useState} from 'react';
import styled from 'styled-components';
import {RectButton} from '../../Components/RectButton';
import {
  FlexColumnStartStart,
  FlexRowCenterBetween,
  Text10,
  Text12,
} from '../../widget';
import {Color, INPUT_TYPES} from '../../constants';
import {useForm} from 'react-hook-form';
import {Input} from '../../Components/Input';
import {useOutlet} from 'reconnect.js';
import {navigate} from 'gatsby-link';
import {Checkbox} from 'antd';
import {useCountDownTimer} from '../../Hooks/useCountDownTimer';
import useModal from '../../Hooks/useModal';
import {AlertModalContent} from '../../Components/AlertModalContent';

const Policy = (props) => {
  const {onClick} = props;
  const [isChecked, setIsChecked] = useState(false);
  return (
    <div className="view">
      <Text12 style={{fontWeight: 'bold', marginBottom: 10}}>
        一般會員須知
      </Text12>
      <div className="inner-content-scroll-view">
        <div className="content">
          親愛的客戶您好，歡迎加入網站會員。
          為保障您的會員權益敬請在您註冊加入會員前詳細閱讀以下會員權益須知：
        </div>
        <div className="title">1.貴我雙方同意且接受條款：</div>
        <div className="content">
          網站係依據本項條款提供（http://www.com.tw）網站中各項會員服務
        </div>
        <div className="content">
          (以下簡稱「本服務」)。當您使用本服務時，即表示您已全部閱讀且瞭解並同意接受本約定書之所有內容。方得使用或繼續使用本服務。當您使用或繼續使用本服務時，即推定您的家長（或監護人）已全部閱讀且瞭解並同意接受本約定書之所有內容及其後修改變更。
        </div>
        <div className="title">2.會員註冊必須注意事項：</div>
        <div className="content">
          A.依本服務註冊表之提示請您提供本人正確、最新及完整的資料。
        </div>
        <div className="content">
          B.影城將維持並更新您個人資料，確保您的個人資料為正確、最新及完整狀態。
        </div>
        <div className="title">
          3.會員如有下列情形發生時，影城網站得保留終止您的會員資格及您享有各項服務之權利：
        </div>
        <div className="content">
          您同意且絕不為任何非法目的或以任何非法方式使用本服務，並承諾遵守影城網站交易規定、消費者保護
        </div>
      </div>
      <div style={{flex: 1}} />
      <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
        <Checkbox
          style={{
            color: Color.orange,
            outline: Color.orange,
            fontSize: 10,
            fontWeight: 'bold',
          }}
          onChange={(e) => {
            const {checked} = e.target;
            setIsChecked(checked);
          }}>
          我同意以上條款
        </Checkbox>
      </div>
      <Text10
        style={{
          fontWeight: 'bold',
          marginBottom: 10,
          color: Color.orange,
          width: '100%',
          textAlign: 'center',
        }}>
        加入會員前請詳細瀏覽上列條款，註冊即表示同意會員條款
      </Text10>
      <RectButton
        disabled={!isChecked}
        onClick={onClick}
        style={{width: '100%'}}>
        前往註冊
      </RectButton>
    </div>
  );
};

const FORGOT_PASSWORD_STEPS = {
  email: 'email',
  validation: 'validation',
  forgotPassword: 'forgotPassword',
};

function StepOfValidation(props) {
  const {
    emailForm,
    setCurStep,
    validationFrom,
    setAccessToken,
    validationState,
    setValidationState,
  } = props;
  const [actions] = useOutlet('actions');
  const [isLoading, setIsLoading] = useState(false);
  const [isEnableResentOtp, setIsEnableResentOtp] = useState(false);
  const [seconds, setSeconds] = useCountDownTimer(() => {
    setIsEnableResentOtp(true);
  });
  const [isValidateLoading, setIsValidateLoading] = useState(false);
  const [isValidateFailed, setIsValidateFailed] = useState(false);

  const EMAIL_VALIDATION_FIELD = {
    placeholder: '請輸入驗證碼',
    name: 'otp',
    defaultValue: '',
    rules: {
      required: true,
    },
    message: {
      required: '驗證碼不可為空',
    },
    label: '驗證碼',
    type: INPUT_TYPES.text,
  };

  const onResentOtp = async () => {
    const email = emailForm.getValues('email');
    try {
      setIsLoading(true);
      const resp = await actions.genForgotPasswordOtp({email});
      const {validation} = resp;
      setValidationState(validation);
      setSeconds(120);
      setIsEnableResentOtp(false);
    } catch (e) {
      alert('寄送驗證信失敗');
      setSeconds(0);
      setIsEnableResentOtp(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsValidateLoading(true);
      const {otp} = data;
      const values = {
        state: validationState,
        code: otp,
      };
      const resp = await actions.validateOtp(values);
      const {access_token} = resp;
      setAccessToken(access_token);
      setCurStep(FORGOT_PASSWORD_STEPS.forgotPassword);
    } catch (e) {
      setIsValidateFailed(true);
    } finally {
      setIsValidateLoading(false);
    }
  };

  return (
    <div className="view">
      <Input
        field={EMAIL_VALIDATION_FIELD}
        register={validationFrom.register(
          EMAIL_VALIDATION_FIELD.name,
          EMAIL_VALIDATION_FIELD.rules,
        )}
        errors={validationFrom.formState.errors}
      />
      <Text10
        style={{
          fontWeight: 'bold',
          marginBottom: 10,
          color: Color.orange,
          width: '100%',
          textAlign: 'left',
        }}>
        系統已寄送驗證信至您的信箱
      </Text10>
      <Text10
        style={{
          fontWeight: 'bold',
          marginBottom: 10,
          color: Color.orange,
          width: '100%',
          textAlign: 'left',
        }}>
        若兩分鐘之內尚未收到請點選重新寄送驗證信
      </Text10>
      <RectButton
        isLoading={isLoading}
        disabled={!isEnableResentOtp}
        onClick={onResentOtp}
        style={{width: '100%', marginTop: 40}}>
        {`重新寄送驗證信 ${seconds === 0 ? '' : `( ${seconds} s )`}`}
      </RectButton>
      <div style={{flex: 1}} />

      {isValidateFailed && (
        <Text12
          style={{
            fontWeight: 'bold',
            color: Color.orange,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 15,
            width: '100%',
          }}>
          驗證碼錯誤
        </Text12>
      )}
      <RectButton
        isLoading={isValidateLoading}
        onClick={validationFrom.handleSubmit(onSubmit)}
        style={{width: '100%'}}>
        前往更改密碼
      </RectButton>
    </div>
  );
}

const StepOfEmail = (props) => {
  const {emailForm, setCurStep, setValidationState} = props;
  const [actions] = useOutlet('actions');
  const [isLoading, setIsLoading] = useState(false);

  const EMAIL_FIELD = {
    placeholder: '請輸入會員信箱',
    name: 'email',
    defaultValue: '',
    rules: {
      required: true,
      pattern: /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/,
    },
    message: {
      required: '會員信箱不可為空',
      pattern: '信箱格式不正確',
    },
    label: '會員信箱',
    type: INPUT_TYPES.text,
    hint: '避免使用PChome信箱以免收不到認證信',
  };

  const onSubmit = async (formData) => {
    try {
      setIsLoading(true);
      const resp = await actions.genForgotPasswordOtp(formData);
      const {validation} = resp;
      setValidationState(validation);
      setCurStep(FORGOT_PASSWORD_STEPS.validation);
    } catch (e) {
      alert('寄送驗證信失敗');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="view">
      <Input
        field={EMAIL_FIELD}
        register={emailForm.register(EMAIL_FIELD.name, EMAIL_FIELD.rules)}
        errors={emailForm.formState.errors}
      />
      <div style={{flex: 1}} />
      <RectButton
        isLoading={isLoading}
        onClick={emailForm.handleSubmit(onSubmit)}
        style={{width: '100%'}}>
        寄送驗證信
      </RectButton>
    </div>
  );
};

function StepOfForgotPassword(props) {
  const {accessToken, forgotPasswordForm, modalRef} = props;
  const [isLoading, setIsLoading] = useState(false);
  const [actions] = useOutlet('actions');

  const FIELDS = useMemo(
    () => [
      {
        placeholder: '請輸入會員密碼',
        name: 'password',
        defaultValue: '',
        rules: {
          required: true,
          pattern: /^[A-Za-z0-9]{8,12}$/,
        },
        message: {
          required: '會員密碼不可為空',
          pattern: '密碼需為 8-12 位英數字組合',
        },
        label: '會員密碼',
        type: INPUT_TYPES.password,
      },
      {
        placeholder: '請輸入密碼確認',
        name: 'confirm',
        defaultValue: '',
        rules: {
          required: true,
          validate: {
            sameAsPassword: (value) =>
              forgotPasswordForm.watch('password') === value,
          },
        },
        message: {
          required: '密碼確認不可為空',
          sameAsPassword: '密碼確認需與密碼一致',
        },
        label: '密碼確認',
        type: INPUT_TYPES.password,
      },
    ],
    [forgotPasswordForm],
  );

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const {password} = data;
      const payload = {
        password,
        accessToken,
      };
      await actions.updatePassword(payload);
    } catch (e) {
      modalRef.current.open();
      modalRef.current.set({
        elem: (
          <AlertModalContent
            title="密碼更新失敗"
            subtitle="密碼更新失敗，請稍後重新嘗試"
            onConfirm={async () => {
              modalRef.current.close();
              await navigate('/profile');
            }}
          />
        ),
      });
    } finally {
      setIsLoading(false);
      modalRef.current.open();
      modalRef.current.set({
        elem: (
          <AlertModalContent
            title="密碼更新成功"
            subtitle="密碼更新成功，往後請使用新密碼登入"
            onConfirm={async () => {
              modalRef.current.close();
              await navigate('/profile');
            }}
          />
        ),
      });
    }
  };

  return (
    <div className="view">
      {FIELDS.map((f) => (
        <Input
          style={{marginBottom: 10}}
          key={f.name}
          field={f}
          register={forgotPasswordForm.register(f.name, f.rules)}
          errors={forgotPasswordForm.formState.errors}
        />
      ))}

      <div style={{flex: 1}} />

      <RectButton
        style={{width: '100%'}}
        isLoading={isLoading}
        onClick={forgotPasswordForm.handleSubmit(onSubmit)}>
        確定更改密碼
      </RectButton>
    </div>
  );
}

function ForgotPasswordPage(props) {
  const [curStep, setCurStep] = useState(FORGOT_PASSWORD_STEPS.email);
  const emailForm = useForm();
  const validationFrom = useForm();
  const forgotPasswordForm = useForm();
  const [validationState, setValidationState] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const {modalRef, modalElem} = useModal();

  return (
    <Wrapper>
      {curStep === FORGOT_PASSWORD_STEPS.email && (
        <StepOfEmail
          emailForm={emailForm}
          setCurStep={setCurStep}
          setValidationState={setValidationState}
        />
      )}
      {curStep === FORGOT_PASSWORD_STEPS.validation && (
        <StepOfValidation
          setCurStep={setCurStep}
          emailForm={emailForm}
          validationFrom={validationFrom}
          setAccessToken={setAccessToken}
          validationState={validationState}
          setValidationState={setValidationState}
        />
      )}
      {curStep === FORGOT_PASSWORD_STEPS.forgotPassword && (
        <StepOfForgotPassword
          modalRef={modalRef}
          forgotPasswordForm={forgotPasswordForm}
          accessToken={accessToken}
        />
      )}
      {modalElem}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0 auto;
  max-width: var(--contentMaxWith);
  padding: var(--basePadding);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--topNavBarHeight));
  & > .view {
    flex: 1;
    ${FlexColumnStartStart};
    overflow-y: auto;
    & > .row {
      width: 100%;
      ${FlexRowCenterBetween};
    }
    & > .inner-content-scroll-view {
      flex: 1;
      max-height: 350px;
      background-color: ${Color.black_10};
      border-radius: 10px;
      width: 100%;
      padding: 7px 15px;
      overflow-y: auto;
    }
    & > .buttons-container {
      width: 100%;
      ${FlexRowCenterBetween};
    }
  }
`;

export default ForgotPasswordPage;
